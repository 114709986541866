<template>
    <div class="mt-3" v-if="reviews.length > 0">
        <v-divider class="my-3"></v-divider>
        <h2>ביקורות</h2>
        <v-list>
            <v-list-item v-for="(review, index) in reviews" :key="index">
                <v-list-item-content>
                    <v-list-item-subtitle class="text--primary">{{ review.fromName
                        }}:</v-list-item-subtitle>
                    <v-list-item-title>
                        <v-rating v-model="review.rating" readonly></v-rating>
                    </v-list-item-title>

                    <v-list-item-subtitle>{{ review.comment }}</v-list-item-subtitle>
                    <v-list-item-subtitle> תאריך פרסום הביקורת: {{ formatDateToDayView(review.createdAt)
                        }}</v-list-item-subtitle>
                    <v-divider v-if="index < reviews.length - 1" :key="index"></v-divider>
                </v-list-item-content>

            </v-list-item>

        </v-list>
    </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
    name: 'MyReviews',
    data() {
        return {
            reviews: []
        };
    },
    async created() {
        await this.getUserReviews();
    },
    computed: {
        ...mapGetters(["user", "UID"]),
    },
    methods: {
        async getUserReviews() {
            let viewMessage = this;
            let reviewsRef = await fire.database().ref('reviews').orderByChild('uid').equalTo(this.UID);
            reviewsRef.on('value', (snapshot) => {
                let reviews = [];
                snapshot.forEach((childSnapshot) => {
                    reviews.push(childSnapshot.val());
                });
                viewMessage.reviews = reviews;
            });
        },
        formatDateToDayView(date) {
            return new Date(date).toLocaleDateString('he-IL');
        }
    },
};
</script>